import Header from "../sections/Header";
import Footer from "../sections/Footer";
import AboutMain from "../sections/AboutMain";
import '../static/css/about.css'
function About() {
    return (
        <div id="SITE_CONTAINER">
            <div id="main_MF" className="main_MF">
                <div id="SCROLL_TO_TOP" className="Vd6aQZ ignore-focus SCROLL_TO_TOP" tabIndex="-1" role="region"
                     aria-label="top of page">
                    <span className="mHZSwn">top of page</span>
                </div>
                <div id="BACKGROUND_GROUP" className="backgroundGroup_c1dmp BACKGROUND_GROUP">
                    <div id="BACKGROUND_GROUP_TRANSITION_GROUP">
                        <div id="pageBackground_c1dmp" data-media-height-override-type=""
                             data-media-position-override="false" className="pageBackground_c1dmp BmZ5pC">
                            <div id="bgLayers_pageBackground_c1dmp" data-hook="bgLayers" data-motion-part="BG_LAYER"
                                 className="MW5IWV">
                                <div data-testid="colorUnderlay" className="LWbAav Kv1aVt"></div>
                                <div id="bgMedia_pageBackground_c1dmp" data-motion-part="BG_MEDIA"
                                     className="VgO9Yg"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="site-root" className="site-root">
                    <div id="masterPage" className="mesh-layout remove-wrappers masterPage css-editing-scope">
                        <Header/>
                        <AboutMain/>
                        <Footer/>
                    </div>
                </div>
                <div id="SCROLL_TO_BOTTOM" className="Vd6aQZ ignore-focus SCROLL_TO_BOTTOM" tabIndex="-1" role="region"
                     aria-label="bottom of page">
                    <span className="mHZSwn">bottom of page</span>
                </div>
            </div>
        </div>
    );
}

export default About;
