import React, { useState, useEffect } from 'react';
import nav_img from '../static/img/nav_image.png';
import { useLocation } from 'react-router-dom';

function Header() {
    const location = useLocation();
    const [scrolled, setScrolled] = useState(false);
    const [lastScrollTop, setLastScrollTop] = useState(0);

    // Function to return the data-state based on the current path
    const getDataState = (path) => {
        return location.pathname === path ? 'menu selected  link' : 'menu false  link';
    };

    useEffect(() => {
        const handleScroll = () => {
            const currentScroll = window.pageYOffset;

            // If scroll position is greater than 100px
            if (currentScroll > 100) {
                // Detect scroll direction (down or up)
                if (currentScroll - lastScrollTop > 10) {
                    setScrolled(true); // Scrolling down
                } else if (lastScrollTop - currentScroll > 10) {
                    setScrolled(false); // Scrolling up
                }
            } else {
                // If scroll is less than 100px, reset to false
                setScrolled(false);
            }

            // Update the last scroll position
            setLastScrollTop(currentScroll);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop]);

    return (
        <header
            id="SITE_HEADER"
            className={`xU8fqS ${scrolled ? 'hFwGTD' : 'IQgXoP'} l4oO6c SITE_HEADER wixui-header`}
            tabIndex="-1"
        >
            <div className="_C0cVf">
                <div className="_4XcTfy" data-testid="screenWidthContainerBg"></div>
            </div>
            <div className="U4Bvut">
                <div className="G5K6X8">
                    <div className="gUbusX" data-testid="screenWidthContainerBgCenter"></div>
                </div>
                <div className="CJF7A2">
                    <div
                        data-mesh-id="SITE_HEADERinlineContent"
                        data-testid="inline-content"
                        className=""
                    >
                        <div
                            data-mesh-id="SITE_HEADERinlineContent-gridContainer"
                            data-testid="mesh-container-content"
                        >
                            <div
                                id="comp-jy05jif9"
                                className="MazNVa comp-jy05jif9 wixui-image"
                                title=" VerdeGlobal_logo_horizontal.png"
                            >
                                <div data-testid="linkElement" className="j7pOnl">
                                    <wow-image
                                        id="img_comp-jy05jif9"
                                        className="HlRz5e BI8PVQ"
                                        data-image-info='{"containerId":"comp-jy05jif9","displayMode":"fill","targetWidth":250,"targetHeight":50,"isLQIP":false,"imageData":{"width":1113,"height":223,"uri":"ffe394_28cf8317c43c4bffb5e266a99e943381~mv2.png","name":"","displayMode":"fill"}}'
                                        data-motion-part="BG_IMG"
                                        data-bg-effect-name=""
                                        data-has-ssr-src=""
                                    >
                                        <img
                                            src={nav_img}
                                            alt=""
                                            style={{width: '250px', height: '50px', objectFit: 'cover'}}
                                            width="250"
                                            height="50"
                                            fetchPriority="high"
                                        />
                                    </wow-image>
                                </div>
                            </div>
                            <wix-dropdown-menu
                                id="comp-isyhm8wh"
                                className="XwCBRN NHM1d1 comp-isyhm8wh wixui-dropdown-menu hidden-during-prewarmup"
                                tabindex="-1"
                                dir="ltr"
                                data-stretch-buttons-to-menu-width="false"
                                data-same-width-buttons="false"
                                data-num-items="5"
                                data-menuborder-y="0"
                                data-menubtn-border="0"
                                data-ribbon-els="0"
                                data-label-pad="0"
                                data-ribbon-extra="0"
                                data-dropalign="right"
                                style={{visibility: 'inherit', overflowX: 'visible'}}
                                data-dropmode="dropDown"
                            >
                                <nav
                                    className="R_TAzU"
                                    id="comp-isyhm8whnavContainer"
                                    aria-label="Site"
                                >
                                    <ul
                                        className="y7qwii"
                                        id="comp-isyhm8whitemsContainer"
                                        style={{textAlign: 'right'}}
                                        data-marginallchildren="true"
                                    >
                                        <li
                                            id="comp-isyhm8wh0"
                                            data-direction="ltr"
                                            data-listposition="center"
                                            data-data-id="bmi23nr"
                                            data-state={getDataState('/')}
                                            data-index="0"
                                            className="Tg1gOB wixui-dropdown-menu__item xTjc1A"
                                            data-original-gap-between-text-and-btn="10"
                                            aria-hidden="false"
                                            style={{
                                                width: '75px',
                                                height: '50px',
                                                position: 'relative',
                                                boxSizing: 'border-box',
                                                overflow: 'visible',
                                                visibility: 'inherit',
                                            }}
                                        >
                                            <a
                                                data-testid="linkElement"
                                                href="/"
                                                target="_self"
                                                className="UiHgGh"
                                                aria-haspopup="false"
                                            >
                                                <div className="yRj2ms">
                                                    <div className="" style={{textAlign: 'right'}}>
                                                        <p
                                                            className="JS76Uv"
                                                            style={{textAlign: 'right', lineHeight: '50px'}}
                                                            id="comp-isyhm8wh0label"
                                                        >
                                                            Home
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li
                                            id="comp-isyhm8wh1"
                                            data-direction="ltr"
                                            data-listposition="center"
                                            data-data-id="dataItem-jxly0mu1"
                                            data-state={getDataState('/ourProcess')}
                                            data-index="1"
                                            className="Tg1gOB wixui-dropdown-menu__item xTjc1A"
                                            data-original-gap-between-text-and-btn="10"
                                            aria-hidden="false"
                                            style={{
                                                width: '121px',
                                                height: '50px',
                                                position: 'relative',
                                                boxSizing: 'border-box',
                                                overflow: 'visible',
                                                visibility: 'inherit',
                                            }}
                                        >
                                            <a
                                                data-testid="linkElement"
                                                href="/ourProcess"
                                                target="_self"
                                                className="UiHgGh"
                                                aria-haspopup="false"
                                            >
                                                <div className="yRj2ms">
                                                    <div className="" style={{textAlign: 'right'}}>
                                                        <p
                                                            className="JS76Uv"
                                                            style={{textAlign: 'right', lineHeight: '50px'}}
                                                            id="comp-isyhm8wh1label"
                                                        >
                                                            Our Process
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li
                                            id="comp-isyhm8wh3"
                                            data-direction="ltr"
                                            data-listposition="center"
                                            data-data-id="dataItem-isyjrjb2"
                                            data-state={getDataState('/about')}
                                            data-index="3"
                                            className="Tg1gOB wixui-dropdown-menu__item xTjc1A"
                                            data-original-gap-between-text-and-btn="10"
                                            aria-hidden="false"
                                            style={{
                                                width: '75px',
                                                height: '50px',
                                                position: 'relative',
                                                boxSizing: 'border-box',
                                                overflow: 'visible',
                                                visibility: 'inherit',
                                            }}
                                        >
                                            <a
                                                data-testid="linkElement"
                                                href="/about"
                                                target="_self"
                                                className="UiHgGh"
                                                aria-haspopup="false"
                                            >
                                                <div className="yRj2ms">
                                                    <div className="" style={{textAlign: 'right'}}>
                                                        <p
                                                            className="JS76Uv"
                                                            style={{textAlign: 'right', lineHeight: '50px'}}
                                                            id="comp-isyhm8wh3label"
                                                        >
                                                            About
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li
                                            id="comp-isyhm8wh4"
                                            data-direction="ltr"
                                            data-listposition="right"
                                            data-data-id="dataItem-jxm1ug2g"
                                            data-state={getDataState('/contact')}
                                            data-index="4"
                                            className="Tg1gOB wixui-dropdown-menu__item xTjc1A"
                                            data-original-gap-between-text-and-btn="10"
                                            aria-hidden="false"
                                            style={{
                                                width: '88px',
                                                height: '50px',
                                                position: 'relative',
                                                boxSizing: 'border-box',
                                                overflow: 'visible',
                                                visibility: 'inherit',
                                            }}
                                        >
                                            <a
                                                data-testid="linkElement"
                                                href="/contact"
                                                target="_self"
                                                className="UiHgGh"
                                                aria-haspopup="false"
                                            >
                                                <div className="yRj2ms">
                                                    <div className="" style={{textAlign: 'right'}}>
                                                        <p
                                                            className="JS76Uv"
                                                            style={{textAlign: 'right', lineHeight: '50px'}}
                                                            id="comp-isyhm8wh4label"
                                                        >
                                                            Contact
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                    <div
                                        className="h3jCPd"
                                        id="comp-isyhm8whdropWrapper"
                                        data-dropalign="right"
                                        data-dropdown-shown="false"
                                    >
                                        <ul
                                            className="wkJ2fp wixui-dropdown-menu__submenu"
                                            id="comp-isyhm8whmoreContainer"
                                        ></ul>
                                    </div>
                                    <div
                                        style={{display: 'none'}}
                                        id="comp-isyhm8whnavContainer-hiddenA11ySubMenuIndication"
                                    >
                                        Use tab to navigate through the menu items.
                                    </div>
                                </nav>
                            </wix-dropdown-menu>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
