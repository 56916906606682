
import Home from "./pages/Home";

import OurProcess from "./pages/OurProsess";
import About from "./pages/About";
import Contact from "./pages/Contact";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from "react";


function App() {
    useEffect(() => {
        AOS.init({
            // You can customize AOS options here
            duration: 1200, // Animation duration
            once: true, // Whether animation should happen only once - while scrolling down
        });
    }, []);
  return (

      <Router>
        <Routes>

          <Route path="/" element={<Home />} />

          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/ourProcess" element={<OurProcess />} />
        </Routes>
      </Router>



  );
}

export default App;
